import React from "react"
import useOverview from "../hooks/use-overview"
import get from "lodash/get"
import BarChart from "./BarChart"
import TrackVisibility from "react-on-screen"

const PoliciesBarChart = (props) => {
  const policiesData = get(useOverview(), "policies.all") || [];
  const data = policiesData.map(policy => ({ x: policy.title, y: policy.total }))
  return (
    <TrackVisibility once partialVisibility>
      {({ isVisible }) => isVisible && <BarChart data={data} {...props} />}
    </TrackVisibility>
  )
}

export default PoliciesBarChart
