import React, { createRef, useEffect, useState } from "react"
import styled from "styled-components"
import includes from "lodash/includes"
import result from "lodash/result"
import get from "lodash/get"
import theme from "../styles/theme"
import { VictoryChart, VictoryAxis, VictoryBar, VictoryTooltip } from "victory"

const GraphTooltip = (props) => {
  const { datum, x, y, highlightColor } = props
  const [offset, updateOffset] = useState(null)
  const elementRef = createRef()
  useEffect(() => {
    const windowWidth = typeof (window) !== "undefined" ? get(window, "innerWidth") : null
    const bounds = result(elementRef.current, "getBoundingClientRect")
    if (bounds && offset === null) {
      if (bounds.x < 0) {
        updateOffset(Math.abs(bounds.x))
        return
      }
      if (windowWidth) {
        if (bounds.x + bounds.width > windowWidth) {
          updateOffset(windowWidth - (bounds.x + bounds.width + 16))
          return
        }
      }
      updateOffset(0)
    }
  }, [elementRef, offset])
  const currentOffset = offset === null ? 0 : offset
  return (
    <g style={{ pointerEvents: 'none', opacity: offset === null ? 0 : 1 }}>
      <foreignObject x={x - 150 + currentOffset} y={y - 110} width="300" height="115" ref={elementRef}>
        <GraphTooltipWrapper className="graph-tooltip" offset={currentOffset} highlightColor={highlightColor}>
          <div className="graph-tooltip__inner">
            <div className="title">{`${datum.y} ${datum.y > 1 ? 'cities' : 'city'}`}</div>
            <div className="text">{datum.x}</div>
          </div>
        </GraphTooltipWrapper>
      </foreignObject>
    </g>
  )
}

const BarChart = ({
  width = 700,
  height = 420,
  data = [],
  mode = "light",
  selectedItems = [],
  barWidth = 17,
  highlightColor = "grey1",
  padding = { left: 30, right: 20, top: 5, bottom: 5 },
  domainPadding = { x: 25, y: 35 }
}) => {
  return (
    <Wrapper highlightColor={highlightColor}>
      <VictoryChart
        animate={{ duration: 500, easing: "polyInOut" }}
        width={width}
        height={height}
        padding={padding}
        domainPadding={domainPadding}
        style={{
          parent: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
            touchAction: 'auto'
          }
        }}
      >
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          style={styles[mode].yAxis}
        />
        <VictoryAxis
          tickFormat={() => ""}
          style={styles[mode].xAxis}
          independentAxis
        />
        <VictoryBar
          sortOrder={"descending"}
          sortKey={"y"}
          labels={() => ""}
          data={data}
          events={[{
            target: "data",
            eventHandlers: {
              onMouseEnter: () => [{ target: "labels", mutation: props => null }],
              onMouseLeave: () => [{ target: "labels", mutation: props => null }],
              onClick: () => [{ target: "labels", mutation: props => null }]
            }
          }]}
          style={{
            data: {
              cursor: "pointer",
              fill: ({ datum }) => {
                if (mode === "light") {
                  return "white"
                } else {
                  return includes(selectedItems, datum.x) ? theme[highlightColor] : theme.grey1
                }
              }
            }
          }}
          labelComponent={<VictoryTooltip flyoutComponent={<GraphTooltip selectedItems={selectedItems} highlightColor={highlightColor} />} />}
        />
      </VictoryChart>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  user-select: none;

  body.is-ie & {
    .VictoryContainer {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
`

const GraphTooltipWrapper = styled.div`
  &, .graph-tooltip {
    position: relative;
    padding: 5px;
    text-align: left;

    &__inner {
      width: 100%;
      height: calc(100% - 20px);
      padding: 1.25rem;
      border-radius: 12px;
      box-shadow: 0 2px 5px 0 rgba(98, 101, 110, 0.3);
      background: white;
    }

    .title {
      font-family: ${({ theme }) => theme.fonts['roboto']};
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: 0.34px;
      color: ${({ theme, highlightColor }) => theme[highlightColor] || theme.grey1} !important;
      margin-bottom: 10px;
    }

    .text {
      font-family: ${({ theme }) => theme.fonts['vollkorn']};
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: -0.09px;
      color: ${({ theme }) => theme.grey1} !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 0px;
      height: 0px;
      bottom: -15px;
      left: calc(50% - 10px);
      border: 10px solid transparent;
      border-top: 12px solid white;
      transform: translate(${({ offset }) => -offset}px,0);
    }
  }
`

const styles = {
  light: {
    yAxis: {
      axis: {
        stroke: "white"
      },
      grid: {
        stroke: "#dddee0",
        opacity: "0.55",
        strokeWidth: 1
      },
      tickLabels: {
        fill: "white",
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold"
      }
    },
    xAxis: {
      axis: {
        stroke: "white"
      },
      grid: {
        stroke: "transparent"
      }
    }
  },
  dark: {
    yAxis: {
      axis: {
        stroke: "#dddee0"
      },
      grid: {
        stroke: "#dddee0",
        opacity: "0.55",
        strokeWidth: 1
      },
      tickLabels: {
        fill: theme.grey1,
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold"
      }
    },
    xAxis: {
      axis: {
        stroke: "#dddee0"
      },
      grid: {
        stroke: "transparent"
      }
    }
  }
}

export default BarChart
