import React, { useEffect, useState, createRef } from "react"
import debounce from "lodash/debounce"
import get from "lodash/get"

const ResponsiveChart = ({
  children,
  initialWidth,
  initialHeight,
  mobileAspectRatio = 0.78,
  mobileBreakpoint = 500,
  delay = 500
}) => {
  const containerRef = createRef()
  const [dimensions, updateDimensions] = useState({
    width: initialWidth,
    height: initialHeight
  })

  const handleResize = () => {
    const currentWidth = get(containerRef, "current.clientWidth") || initialWidth
    const aspectRatio = currentWidth < mobileBreakpoint
      ? mobileAspectRatio
      : initialHeight / initialWidth
    updateDimensions({
      width: currentWidth,
      height: currentWidth * aspectRatio
    })
  }

  useEffect(() => {
    const onResize = debounce(handleResize, delay)
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [containerRef, delay, initialWidth, initialHeight, mobileBreakpoint, mobileAspectRatio]) // eslint-disable-line

  useEffect(() => {
    handleResize()
  }, []) // eslint-disable-line

  return (
    <div ref={containerRef} style={{ position: "relative", width: "100%", minHeight: dimensions.height }}>
      {children({
        width: dimensions.width,
        height: dimensions.height
      })}
    </div>
  )
}

export default ResponsiveChart
